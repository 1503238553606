import React, { Component } from 'react';
import { ProjectDetail } from './ProjectDetail';
import styled from 'styled-components'


const SearchBar = styled.input`
  padding: 6px;
  margin-top: 8px;
  font-size: 17px;
  border: none;
  border-radius: 4px;
  margin: 0 auto;
  display: block;
  margin-bottom: 12px;
  background-color: #262626;
  color: #fbfbfb;

  &::placeholder {
  color: #fbfbfb;
  opacity: 1; /* Firefox */
}

  &::-ms-input-placeholder { /* Edge 12 -18 */
    color: #fbfbfb;
  }
`

const StyledSearchBadge = styled.span`
  background-color: purple;
  border-radius: 16px;
  cursor: pointer;
  padding: 6px;
  padding-left: 14px;
  padding-right: 14px;
  color: #fbfbfb;
  user-select: none;
  transition: opacity .1s ease-in-out;
   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  &:hover {
    opacity: .8;
  }
`

const SearchBadgeContainer = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
  gap: 4px;
  flex-wrap: wrap;
`

const SearchBadge = ({ str, getCount, setState, active}) => {

  return <StyledSearchBadge style={{ background: active ? '#7a077a' : '#695766'}} onClick={() => setState(str)}>{str} ({getCount(str)})</StyledSearchBadge>
}

export default class Porfolio extends Component {

  constructor() {
    super();
    this.state = {
      detail: null,
      search: '',
    }
  }


  filterProjects(projects, search = this.state.search) {

    if (!search) return projects;

    const includes = (a) => {
      if (!a) return false;
      if (a.toLowerCase().includes(search.toLowerCase())) {
        return true;
      } if (search.toLowerCase().includes(a.toLowerCase())) {
        return true;
      }
    };

    return projects.filter(project => {
      return includes(project.name) || includes(project.description) || includes(project.largeDescription) || includes(project.techniques?.join(' '))
    })
  }

  getCount(str) {
    let resumeData = this.props.resumeData;
    return this.filterProjects(resumeData.portfolio, str).length + this.filterProjects(resumeData.workProjects, str).length
  }
  

  setSearchBadge(str) {
    this.setState({ 
      search: str,
    })
  }

  getSearchBadge(str) {
    return <SearchBadge active={this.state.search === str} str={str} getCount={this.getCount.bind(this)} setState={this.setSearchBadge.bind(this)} />
  }

  render() {
    let resumeData = this.props.resumeData;

    const workProjects = this.filterProjects(resumeData.workProjects);
    const portfolio = this.filterProjects(resumeData.portfolio);

    function openInNewTab(url) {
      var win = window.open(url, '_blank');
      win.focus();
    }

    
    return (
      <section id="portfolio">

      <SearchBar placeholder='Search..' value={this.state.search} onChange={e => this.setState({ search: e.target.value })} />

      <SearchBadgeContainer>
        {this.getSearchBadge('JavaScript')}
        {this.getSearchBadge('React')}
        {this.getSearchBadge('WebGL')}
        {this.getSearchBadge('Three.js')}
        {this.getSearchBadge('C# .NET')}
      </SearchBadgeContainer>

      <div className="row">
        { portfolio.length > 0 && <div className="twelve columns collapsed">
          <h1>Personal projects</h1>
          <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
          {
            portfolio.map((item)=>{
              return(
                <div className="columns portfolio-item" onClick={() => this.setState({ detail: item })} key={item.name}>
                  <div className="item-wrap" style={{ backgroundImage: `url("${item.imgurl}")` }}>
                    
                    <div className='project-description'>
                      <h5>{item.name}</h5>
                      <p>{item.description}</p>
                    </div>
                  </div>
                </div>
              )
            })
          }
          </div>
        </div>}
      </div>

      <div className="row">
        {workProjects.length > 0 && <div className="twelve columns collapsed">
          <h1>Work experience</h1>
          <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
          {
            workProjects.map((item)=>{
              return(
                <div className="columns portfolio-item" onClick={() => this.setState({ detail: item })}  key={item.name}>
                  <div className="item-wrap" style={{ backgroundImage: `url("${item.imgurl}")` }}>
                    
                    <div className='project-description'>
                      <h5>{item.name}</h5>
                      <p>{item.description}</p>
                    </div>
                  </div>
                </div>
              )
            })
          }
          </div>
        </div>
        }
      </div>
      {
        this.state.detail ? <ProjectDetail project={this.state.detail} onClose={() => this.setState({ detail: null })} />  : null
      }
      </section>
        );
  }
}