let resumeData = {
    "imagebaseurl":"https://rbhatia46.github.io/",
    "name": "Jildert Venema",
    "role": "Frontend Developer",
    "linkedinId":"403882158",
    "roleDescription": "I like dabbling in various parts of frontend development and like to learn about new technologies, write technical articles or simply play games in my free time.",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/in/jildert-venema-403882158/",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":"https://github.com/jildertvenema",
          "className":"fa fa-github"
        }
      ],
    "aboutme":"I'm an experienced Full Stack Developer with six years of hands-on experience. I'm great at problem- solving and deliver projects on schedule. Web development is not just my profession; it's my passion. I thrive on tackling new challenges and pushing the boundaries of what's possible.",
    "address":"Netherlands",
    "website":"venemasoftware@gmail.com",
    "education":[
      // {
      //   "UniversityName":"University of Groningen",
      //   "specialization":"Pre-Master Computing Science",
      //   "MonthOfPassing":"",
      //   "YearOfPassing":"2021 - 2021",
      //   // "Achievements":"Some Achievements"
      // },
      {
        "UniversityName":"NHL Stenden University of Applied Sciences",
        "specialization":"HBO Bachelor ICT",
        // "MonthOfPassing":"Aug",
        "YearOfPassing":"2017 - 2020",
        // "Achievements":"HBO Diploma"
      },
    ],
    "work":[
      {
        "CompanyName":"ZiuZ Visual Intelligence",
        "specialization":"Software Developer",
        "MonthOfLeaving":"2021 - Present day",
        "Achievements":"Forensic - Video analyse system in Web"
      },
      {
        "CompanyName":"Graduation Internship - Software Developer",
        "specialization":"Intern Software Developer",
        "MonthOfLeaving":"2020 - 2020",
        "Achievements":"Forensic - Video analyse system in Web"
      },
      {
        "CompanyName":"AppMachine B.V.",
        "specialization":"Intern / Junior Software developer",
        "MonthOfLeaving":"Jan",
        "YearOfLeaving":"2018 - 2020",
        // "Achievements":"Some Achievements"
      }
    ],
    "skillsDescription":"These are my skills",
    "skills":[
      {
        "skillname":"HTML5"
      },
      {
        "skillname":"CSS"
      },
      {
        "skillname":"JavaScript"
      },
      {
        "skillname":"JQuery"
      },
      {
        "skillname":"React.js"
      },
      {
        "skillname":"Webassembly"
      },
      {
        "skillname":"Angular"
      },
      {
        "skillname":"Node.js"
      },
      {
        "skillname":".Net Core"
      },
      {
        "skillname": "SQL"
      },
      {
        "skillname":"Java"
      },
      {
        "skillname":"Python"
      }
    ],
    "workProjects": [
      {
        "name": "DJ Studio",
        "description":"Software to create DJ mixes using web based technologies.",
        "largeDescription":"Software to create DJ mixes using web based technologies.\n My Main tasks were  working in the JavaScript audio engine, creating new UI features using React and WebGL Shaders.",
        "imgurl":"images/portfolio/djstudio.webp",
        "techniques": ["JavaScript", "HTML", "CSS", "React", "WebGL", "WebGL Shaders", "Node.js", "Python"],
        "link": "https://dj.studio/",
      },
      {
        "name": "Media analyze system",
        "description":"A Docker-managed container system designed for inspectors to analyze extensive collections of images and videos.",
        "largeDescription":"A Docker-managed container system designed for inspectors to analyze extensive collections of images and videos.\n My Main tasks were  developing .NET applications, creating the frontend using Blazor Webassembly, configuring the Docker Containers, setting up / querying an ElasticSearch database and creating installer scripts using Azure Pipelines and Bash scripts.",
        "imgurl":"images/portfolio/analyzer.webp",
        "techniques": ["Azure DevOps", "Azure Pipelines", "Blazor Webassembly", "C# .NET", "Bash Scripts", "JavaScript", "HTML", "CSS", "Elastic Search", "SQL"],
      },
      {
        "name": "LogoMaker",
        "description":"An online logo maker for instantly generating high quality logos.",
        "imgurl":"images/portfolio/logo.webp",
        "largeDescription":"An online logo maker for instantly generating high quality logos.\n My Main tasks were creating new features in the Frontend using React",
        "techniques": ["React", "JavaScript", "HTML", "CSS"],
        "link": "https://app.websitebuilder.com/logo",
      },
      {
        "name": "Realtime Video Transcoding in Web",
        "description":"A dashboard made for investigators to watch videos in a webapplication using real time transcoding.",
        "largeDescription":"A dashboard made for investigators to watch videos in a webapplication using real time transcoding. Created as my graduation project for HBO Bachelor's degree. This application enables real-time streaming of videos from various formats directly on the web, eliminating the need for additional storage for transcoded files.",
        "techniques": ["Angular", "JavaScript", "HTML", "CSS", "C# .NET", "FFmpeg"],
        "imgurl":"images/portfolio/videoweb.webp",
      },
      {
        "name": "Inspector web browser plugin",
        "description":"A Google Chrome / FireFox Extension POC made for investigaters to find and mark illegal imagery on the internet.",
        "largeDescription":"A Google Chrome / FireFox Extension POC made for investigaters to find and mark illegal imagery on the internet..\n Investigaters can use this plugin to mark illegal imagery while browsing the internet. Then later these material can be managed in a dashboard to create a report.",
        "imgurl":"images/portfolio/chrome-extention.jpg",
        "techniques": ["Angular", "JavaScript", "HTML", "CSS"],
      },
      {
        "name":"Management Portal",
        "description":"A statistical management portal for displaying and querying large amounts of data developed with React.",
        "largeDescription":"A statistical management portal for displaying and querying large amounts of data developed with React.\n This was my first internship were I created the frontend application using React and two other students created the backend services.",
        "imgurl":"images/portfolio/management.webp",
        "techniques": ["React", "JavaScript", "HTML", "CSS"],
      },
      {
        "name":"Hololens Factory",
        "description":"A Microsoft Hololens application where you can manage machine statuses in mixed reality.",
        "imgurl":"images/portfolio/hololens.webp",
        "techniques": ["Unity", "Microsoft Hololens"],
        "youtube": "https://www.youtube.com/embed/UnAL-x70QG4",
      },
    ],
    "portfolio":[
      {
        "name":"Redcat Spookkasteel WebGL Remake",
        "description":"A remake of the old Davilex Game called: Redcat Spookkasteel.",
        "largeDescription": "Redcat Spookkasteel (Redcat Haunted Castle) is a dutch game that was released in an era when 3D gaming was still in its infancy. It made an impression with its unique gameplay and atmospheric/spooky environments. Decades later, I decided to take on the challenge of modernizing this nostalgic masterpiece and delivering the experience that Redcat and the fans deserve.",
        "link": "https://www.redcatspookkasteel.nl/",
        "imgurl":"images/portfolio/redcat.webp",
        "detailImage": "images/portfolio/rc.webp",
        "techniques": ["WebGL", "Three.js", "JavaScript", "HTML", "CSS"],
      },
      {
        "name":"Wardrobe Challenge Solver",
        "description":"A frontend application that solves a the Wardobe challenge game of GoSupermodel. With currently more than 1k users.",
        "largeDescription": "A frontend application that solves a the Wardobe challenge game of GoSupermodel. With currently more than 1k users.",
        "imgurl":"images/portfolio/GSM.webp",
        "detailImage": "images/portfolio/GSM.webp",
        "link": "https://jildertvenema.github.io/grid-solver/",
        "techniques": ["JavaScript", "HTML", "CSS", "Breadth-first search", "Depth-first search", "Python"],
      },
      {
        "name":"Shoots",
        "description":"A mobile webbased multiplayer party game. Play agains your friends and answer questions about your friends in the multiplayer quiz.",
        "largeDescription": "The game must be played by at least 2 people. The questions are about 1 group member (also called a game master). First this answer and then the rest. The winner of the round is determined by the correct answers and speed. The winner of the question round can then hand out drinks to the rest of the group. If no one gets it right, the game master wins. When everyone has drunk, the game master can start the next round. At the end, the drinks are counted and an overall winner is determined based on the number of least drinks drunk and a rematch or a new game can be started.",
        "imgurl":"images/portfolio/shoots.webp",
        "detailImage":"images/portfolio/shoots.webp",
        "techniques": ["JavaScript", "HTML", "CSS", "WebSockets", "Node.js", "MongoDB"],
      },
      // {
      //   "name":"Chrome extension - Marktplaats anti scam",
      //   "description":"A Google Chrome extension for detecting scammers using reverse image search and checking the sellers phone number and account number with the police API.",
      //   "imgurl":"images/portfolio/marktplaats.png",
      //   "link": "https://chrome.google.com/webstore/detail/marktplaats-anti-scam/pcbafmkiocdojcghmhdmmpjlebcehodn"
      // },
      // {
      //   "name":"Spotify friends playlist generator",
      //   "description":"A webapplication that generates a spotify playlist based on your and your friends music interests.",
      //   "imgurl":"images/portfolio/project2.png",
      //   "link": "https://jildertvenema.github.io/spotify-friends-app/"
      // },
      // {
      //   "name":"Boggle",
      //   "description":"A mobile webbased multiplayer game. Play Boggle multiplayer with your friends.",  
      //   "imgurl":"images/portfolio/boggle.png",
      //   "link": "https://boggle.jildert.dev"
      // },
      // {
      //   "name":"Whatsapp backup vizualiser",
      //   "description":"A webapplication that let's you import and view your Whatsapp backup files.",
      //   "imgurl":"images/portfolio/project4.png",
      //   "link": "https://jildertvenema.github.io/whatsapp/"
      // },
      // {
      //   "name":"Whatsapp backup chat bot AI",
      //   "description":"A webapplication that let's you chat with a bot, created with your Whatsapp backup file.",
      //   "imgurl":"images/portfolio/chat.png",
      //   "link": "https://chat.jildert.dev"
      // },
      {
        "name":"Abgewaschen",
        "description":"A school project where we made a webbased co-op game. 'You are stranded on a deserted island and you have to find a way to survive...'.",
        "imgurl":"images/portfolio/project6.png",
        "largeDescription":"A school project where we made a webbased co-op game. 'You are stranded on a deserted island and you have to find a way to survive...'.",
        "techniques": ["WebGL", "Three.js", "JavaScript", "HTML", "CSS"],
        "link": "https://abgewaschen.jildert.dev/",
      },
      // {
      //   "name":"Dance and Vision detection Robot",
      //   "description":"A school project where we made a robot that can dance and detect objects.",
      //   "imgurl":"images/portfolio/project5.jpg",
      //   "link": "https://github.com/jildertvenema/IDP"
      // },
      {
        "name":"JavaScript React Chess Bot",
        "description":"A Chess bot in Javascript using the MiniMax algorithm and Alpha-Beta pruning.",
        "imgurl":"images/portfolio/chess.png",
        "link": "https://chess.jildert.dev/",
        "techniques": ["JavaScript", "HTML", "CSS", "Minimax algorithm", "React"],
      },
      {
        "name":"Marktplaats anti scam Chrome extension",
        "description":"Google Chrome extension used on an online marketplace to detect scammers using reverse image search and the public Police API. Telephone numbers and bank numbers are parsed from the chat via regex and validated for fraud at the public API of the police",
        "imgurl":"images/portfolio/chrome-extention.jpg",
        "techniques": ["JavaScript", "HTML", "CSS"],
      },
    ],
    "testimonials":[
      {
        "description":"“The computer was born to solve problems that did not exist before.”",
        "name":"Bill Gates"
      },
      {
        "description":"“Computer Science is no more about computers than astronomy is about telescopes.”",
        "name":"Edsger W. Dijkstraf"
      }
    ]
  }
  
  export default resumeData
