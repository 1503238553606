import React, { Component } from 'react';
import Header from './components/Header';
import About from './components/About';
// import Resume from './components/Resume';
import Portfolio from './components/Portfolio';
// import Testimonials from  './components/Testimonials';
// import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import resumeData from './resumeData';


import { ErrorBoundary } from "react-error-boundary";

function fallbackRender({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
}


class App extends Component {
  render() {
    return (
      <ErrorBoundary
        fallbackRender={fallbackRender}
        onReset={(details) => {
          // Reset the state of your app so the error doesn't happen again
        }}
        >
        <div className="App">
          <Header resumeData={resumeData}/>
          <About resumeData={resumeData}/>
          {/* <Resume resumeData={resumeData}/> */}
          <Portfolio resumeData={resumeData}/>
          {/* <Testimonials resumeData={resumeData}/> */}
          {/* <ContactUs resumeData={resumeData}/> */}
          <Footer resumeData={resumeData}/>
        </div>
      
      </ErrorBoundary>
    );
  }
}

export default App;