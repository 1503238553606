import { useEffect, useState } from "react"
import styled from 'styled-components';
import Collapsible from 'react-collapsible';


const ProjectName = styled.div`
    text-align: center;
    font-size: 24px;
    color: #fbfbfb;
    font-family: 'opensans-bold', sans-serif;
    font-weight: normal;
    padding-top: 8px;
`

const CloseButton = styled.i`
    font-size: 24px;
    color: #fbfbfb;
    cursor: pointer;
    position: absolute;
    right: 12px;
    top: 12px;
`

const Body = styled.div`
    max-width: 800px;
    margin: 0 auto;
    padding: 8px;
    color: #fbfbfb;
`
const Description = styled.div`
    margin-top: 12px;
    line-height: initial;
`

const Image = styled.div`
    height: 400px;
    max-height: 50vw;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    margin-top: 12px;
`

const StyledCollapsible = styled(Collapsible)`
    width: 50%;
`

const StyledCollapsibleItem = styled.p`
    margin: 8px;
`

const CollapseIcon = styled.i`
    font-size: 12px;
    margin-left: 8px;
    margin-top: -2px;
`

export const ProjectDetail = ({ onClose, project }) => {
    const [frontendOpen, setFrontendOpen] = useState(false);

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => document.body.style.overflow = 'auto';
    }, [])

    return <div onClick={onClose} style={{ position: 'fixed', inset: 0, background: '#0000007d', zIndex: 999 }}>
        <div onClick={e => e.stopPropagation()} style={{  overflowY: "auto", paddingBottom: 28, paddingTop: 16, position: 'relative', margin: '0 auto', width: '100%', maxWidth: 1100, background: '#3e3e42', borderRadius: 4, maxHeight: '80%', marginTop: '5%', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
            <ProjectName>{project.name}</ProjectName>
            <CloseButton className='fa fa-times' onClick={onClose} />

            <Body>
                {
                    project.youtube ? <iframe width="800" height="400"
                    src={project.youtube}>
                    </iframe> : <Image style={{ backgroundImage: `url("${project.detailImage ?? project.imgurl}")` }} />
                }
                <Description>{(project.largeDescription ?? project.description).split('\n').map(str => <div style={{ marginTop: 8}}>{str}</div>)}</Description>
                {project.link ? <Description>Check <a href={project.link} target="_blank">this link</a> for more information / online demo.</Description> : null}

                {(project.techniques) ? <div style={{ height: 16 }}></div>: null}
                {project.techniques ? 
                    <StyledCollapsible onOpen={() => setFrontendOpen(true)} onClose={() => setFrontendOpen(false)} trigger={<span style={{ paddingLeft: 8, paddingBottom: 4, userSelect: "none" }}>{'Techniques used'} <CollapseIcon className={frontendOpen ?'fa fa-chevron-up' : 'fa fa-chevron-down'}/></span>}>
                        {project.techniques.map(str => <StyledCollapsibleItem>{str}</StyledCollapsibleItem>)}
                    </StyledCollapsible>
                : null}


            </Body>
        </div>
    </div>
}